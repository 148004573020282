@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Poppins";
    font-weight: 100;
    src: url("/public/fonts/Poppins-Thin.ttf");
  }

  @font-face {
    font-family: "Poppins";
    font-weight: 200;
    src: url("/public/fonts/Poppins-ExtraLight.ttf");
  }

  @font-face {
    font-family: "Poppins";
    font-weight: 300;
    src: url("/public/fonts/Poppins-Light.ttf");
  }

  @font-face {
    font-family: "Poppins";
    font-weight: 400;
    src: url("/public/fonts/Poppins-Regular.ttf");
  }

  @font-face {
    font-family: "Poppins";
    font-weight: 500;
    src: url("/public/fonts/Poppins-Medium.ttf");
  }

  @font-face {
    font-family: "Poppins";
    font-weight: 600;
    src: url("/public/fonts/Poppins-SemiBold.ttf");
  }

  @font-face {
    font-family: "Poppins";
    font-weight: 700;
    src: url("/public/fonts/Poppins-Bold.ttf");
  }

  @font-face {
    font-family: "Poppins";
    font-weight: 800;
    src: url("/public/fonts/Poppins-ExtraBold.ttf");
  }

  body {
    @apply text-center font-poppins font-normal;
  }

  .animation {
    @apply transition-all duration-300 ease-in-out;
  }

  .base-button {
    @apply md:px-6 md:py-3 px-3 py-2 text-center w-full rounded-xl bg-primary-900 animation hover:scale-105 hover:bg-white hover:text-primary-900;
  }

  .fixed-button {
    @apply md:px-6 md:py-4 px-5 py-3 bg-primary-700 rounded-l-2xl text-white font-medium shadow-xl hover:bg-slate-50 hover:text-primary-900 hover:scale-x-110 animation;
  }

  .nav-item {
    @apply text-primary-700 hover:bg-gray-transparent animation rounded-xl p-2;
  }

  .nav-active {
    @apply bg-gray-transparent rounded-xl p-2;
  }

  .mobile-nav-active {
    @apply opacity-100 h-80 pointer-events-auto animation !important;
  }

  .icon-social {
    @apply hover:fill-primary-700 animation;
  }

  .section-default {
    @apply min-h-screen flex items-center justify-center;
  }

  .mobile-section-default {
    @apply h-auto my-10 flex items-center justify-center;
  }

  .footer-default {
    @apply container pt-14 flex items-center justify-center;
  }

  .contact-items {
    @apply h-full flex flex-col items-center justify-center gap-10 py-10 md:bg-primary-transparent rounded-xl md:shadow-2xl md:hover:scale-105 animation;
  }
}