.swiper {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  
.swiper-slide {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 3rem;
    padding: 2rem;
}

.swiper-pagination {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-left: 20px;
    z-index: 40;
}

.swiper-pagination-bullets {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    padding: 6px;
    border-radius: 25px;
    width: 7rem;
}

.swiper-pagination-bullet {
    background-color: #274c6c;
    padding: 10px;
    border-radius: 25px;  
    cursor: pointer;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.swiper-pagination-bullet-active {
    background-color: #f8fafc;
    width: 8rem;
}

@media screen and (max-width:983px) {
    .swiper {
        height: 50vh;
    }
    .swiper-slide {
        justify-content: flex-start;
    } 
}